
import { Component, Mixins, Prop } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

@Component({
  components: {}
})
export default class FormEncuestaServicio extends Mixins(FormValidator) {
  private text = "";
  private submitResult: any;
  private id_ticket: any;
  private token: any;
  private evaluaciones = {
    pregunta1: 0,
    pregunta2: 0,
    pregunta3: 0
  };

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "accent",
      position: "top",
      timeout: 5000
    });
  }

  protected onSubmit(evt: any) {
    const formData = new FormData(evt.target);
    const submitResult = [];

    for (const [name, value] of formData.entries()) {
      submitResult.push({ name, value });
    }

    this.submitResult = submitResult;
    if (
      this.submitResult[0].value == 0 ||
      this.submitResult[1].value == 0 ||
      this.submitResult[2].value == 0
    ) {
      return this.notify("Debe seleccionar una calificación por pregunta");
    }

    this.$q.loading.show();
    this.$axios
      .post("ticket_evaluacions/encuesta", {
        id_ticket: Number(this.id_ticket),
        pregunta_1: parseInt(this.submitResult[0].value),
        pregunta_2: parseInt(this.submitResult[1].value),
        pregunta_3: parseInt(this.submitResult[2].value),
        comentario: this.text,
        token: this.token
      })
      .then(response => {
        this.notify("Se evaluó el servicio correctamente");
        this.$router.push({ name: "home" });
      })
      .catch(error => {
        let message = "Ha ocurrido un error al evaluar el servicio";
        switch (error.response.data.error.message) {
          case "token_no_data":
            message = "La encuesta ya fue respondida";
            break;
          case "invalid_token":
          case "ticket_no_data":
            message = "El token no corresponde a una encuesta";
            break;
          case "wrong_id_ticket":
            message = "La encuesta pertenece a otro ticket";
            break;
          case "expired_token":
            message = "El token ya expiró";
            break;
        }
        this.notify(message);
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private mounted() {
    if (this.$router.currentRoute.params.token) {
      this.token = this.$router.currentRoute.params.token;
      this.id_ticket = this.$router.currentRoute.params.id_ticket;
    } else {
      this.$router.push({ name: "home" });
    }
  }
}
